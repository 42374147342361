import { faGithub } from '@fortawesome/free-brands-svg-icons'
import {
    faArrowDown,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowDownShortWide,
    faArrowLeft,
    faArrowRight,
    faArrowRotateRight,
    faArrowUp,
    faArrowUpArrowDown,
    faArrowUpRightAndArrowDownLeftFromCenter,
    faArrowUpRightFromSquare,
    faArrowUpShortWide,
    faArrowsRepeat,
    faBadgeCheck,
    faBars,
    faBarsFilter,
    faBarsSort,
    faBolt,
    faCalendar,
    faCalendarDays,
    faCalendarPen,
    faCalendarStar,
    faChartLineUp,
    faCheck,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleExclamation,
    faCircleSmall,
    faCircleUp as faCircleUpLight,
    faClock,
    faClone,
    faCode,
    faCopy,
    faCubes,
    faDiagramSubtask,
    faEllipsisVertical,
    faEmptySet,
    faExclamationTriangle,
    faEye,
    faEyeSlash,
    faFlask,
    faFloppyDisk,
    faFolder,
    faGear,
    faGlobe,
    faHouseDay,
    faInbox,
    faInfinity,
    faItalic,
    faLink,
    faLinkSlash,
    faList,
    faListOl,
    faListUl,
    faMagnifyingGlass,
    faMegaphone,
    faMessage,
    faNoteSticky,
    faPenToSquare,
    faPencil,
    faPlus,
    faRankingStar,
    faShare,
    faSidebar,
    faSidebarFlip,
    faSort,
    faSquare,
    faSquareCode,
    faSquareMinus,
    faSquarePlus,
    faStrikethrough,
    faTag,
    faTimer,
    faTrash,
    faUnderline,
    faUser,
    faUsers,
    faVideo,
    faXmark,
} from '@fortawesome/pro-light-svg-icons'
import { faFire, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import {
    faBold,
    faCaretDown,
    faCheckCircle as faCheckCircleSolid,
    faCheckSquare as faCheckSquareSolid,
    faCircleChevronDown,
    faCircleChevronUp,
    faCircleDot,
    faCircleUp,
    faExclamationTriangle as faExclamationTriangleSolid,
    faHeadphones,
    faInfoCircle as faInfoCircleSolid,
    faMinus,
    faPlay,
    faQuoteRight,
    faSquare as faSquareSolid,
} from '@fortawesome/pro-solid-svg-icons'

export const logos = Object.freeze({
    generaltask_single_color: 'images/gt-logo-single-color.svg',
    generaltask_black_on_white: 'images/gt-logo-black-on-white.svg',
    generaltask: '/images/generaltask.svg',
    generaltask_beta_yellow: '/images/GT-beta-logo.png',
    generaltask_beta_blue: '/images/GT-beta-logo-blue.png',
    generaltask_yellow_circle: '/images/gt-logo-yellow-circle.png',
    generaltask_blue_circle: '/images/gt-logo-blue-circle.png',
    github: faGithub,
    gmail: '/images/google.svg',
    gcal: '/images/gcal.png',
    google_meet: '/images/google-meet.svg',
    jira: '/images/jira.svg',
    linear: '/images/linear.png',
    slack: '/images/slack.svg',
})

export const icons = Object.freeze({
    arrow_ascend: faArrowUpShortWide,
    arrow_descend: faArrowDownShortWide,
    arrow_down: faArrowDown,
    arrow_left: faArrowLeft,
    arrow_right: faArrowRight,
    arrow_up: faArrowUp,
    arrow_up_down: faArrowUpArrowDown,
    arrows_in: faArrowDownLeftAndArrowUpRightToCenter,
    arrows_out: faArrowUpRightAndArrowDownLeftFromCenter,
    arrows_repeat: faArrowsRepeat,
    bold: faBold,
    bolt: faBolt,
    calendar_blank: faCalendar,
    calendar_days: faCalendarDays,
    calendar_pen: faCalendarPen,
    calendar_star: faCalendarStar,
    caret_down_solid: faCaretDown,
    caret_down: faChevronDown,
    caret_left: faChevronLeft,
    caret_right: faChevronRight,
    caret_up: faChevronUp,
    chartLineUp: faChartLineUp,
    check_circle_wavy: faBadgeCheck,
    check: faCheck,
    checkbox_checked_solid: faCheckSquareSolid,
    checkbox_checked: faCheckSquare,
    checkbox_unchecked: faSquare,
    checkCircleSolid: faCheckCircleSolid,
    clock: faClock,
    clone: faClone,
    code_block: faSquareCode,
    code: faCode,
    comment: faMessage,
    copy: faCopy,
    domino: faGripDotsVertical,
    dot: faCircleSmall,
    emptySet: faEmptySet,
    ellipsisVertical: faEllipsisVertical,
    exclamationTriangleSolid: faExclamationTriangleSolid,
    external_link: faArrowUpRightFromSquare,
    eye: faEye,
    eye_slash: faEyeSlash,
    filter: faBarsFilter,
    fire: faFire,
    flask: faFlask,
    folder: faFolder,
    gcal: logos.gcal,
    gear: faGear,
    github_high: '/images/github_high.svg',
    github_low: '/images/github_low.svg',
    github_med: '/images/github_med.svg',
    github_paused: '/images/github_paused.svg',
    github: logos.github,
    globe: faGlobe,
    hamburger: faBars,
    headphones: faHeadphones,
    houseDay: faHouseDay,
    inbox: faInbox,
    infinity: faInfinity,
    infoCircleSolid: faInfoCircleSolid,
    italic: faItalic,
    jira: logos.jira,
    label: faTag,
    linear: logos.linear,
    linear_cycle_all: '/images/linear_cycle_all.svg',
    linear_cycle_current: '/images/linear_cycle_current.svg',
    linear_cycle_next: '/images/linear_cycle_next.svg',
    linear_cycle_none: '/images/linear_backlog.svg',
    linear_cycle_previous: '/images/linear_cycle_previous.svg',
    link: faLink,
    link_slashed: faLinkSlash,
    list_ol: faListOl,
    list_ul: faListUl,
    list: faList,
    magnifying_glass: faMagnifyingGlass,
    megaphone: faMegaphone,
    note: faNoteSticky,
    penToSquare: faPenToSquare,
    pencil: faPencil,
    play: faPlay,
    plus: faPlus,
    priority_high: faCircleChevronUp,
    priority_low: faCircleChevronDown,
    priority_medium: faCircleDot,
    priority_none: faMinus,
    priority_urgent: faCircleUp,
    priority: faCircleUpLight,
    quote_right: faQuoteRight,
    rankingStar: faRankingStar,
    repository: faCubes,
    save: faFloppyDisk,
    share: faShare,
    sidebar: faSidebar,
    sidebarFlipped: faSidebarFlip,
    slack: logos.slack,
    sort: faBarsSort,
    sortArrows: faSort,
    spinner: faArrowRotateRight,
    strikethrough: faStrikethrough,
    square: faSquareSolid,
    squarePlus: faSquarePlus,
    squareMinus: faSquareMinus,
    subtask: faDiagramSubtask,
    timer: faTimer,
    trash: faTrash,
    underline: faUnderline,
    user: faUser,
    users: faUsers,
    video: faVideo,
    warning: faCircleExclamation,
    warningTriangle: faExclamationTriangle,
    x: faXmark,
})

export const buttons: { [key: string]: string } = {
    google_sign_in: '/images/google_sign_in.png', //missing svg
}

export const externalStatusIcons = Object.freeze({
    // Linear
    backlog: '/images/linear_backlog.svg',
    unstarted: '/images/linear_todo.svg',
    started: '/images/linear_inprogress.svg',
    completed: '/images/linear_done.svg',
    canceled: '/images/linear_canceled.svg',

    inreview: '/images/linear_inreview.svg',
    triage: '/images/linear_triage.svg',
    duplicate: '/images/linear_duplicate.svg',

    // Jira
    new: '/images/jira/new.svg',
    indeterminate: '/images/jira/indeterminate.svg',
    done: '/images/jira/done.svg',
})

export const focusModeBackground = '/images/focus_mode_background.jpg'
export const noteBackground = '/images/note_background.jpg'
export const checkBig = '/images/check_big.svg'

export type TLogoImage = keyof typeof logos
export type TIconImage = keyof typeof icons
export type TExternalStatusImage = keyof typeof externalStatusIcons
