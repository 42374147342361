export const _1 = '1px'
export const _2 = '2px'
export const _4 = '4px'
export const _8 = '8px'
export const _12 = '12px'
export const _16 = '16px'
export const _24 = '24px'
export const _28 = '28px'
export const _32 = '32px'
export const _36 = '36px'
export const _40 = '40px'
export const _44 = '44px'
export const _48 = '48px'
export const _56 = '56px'
export const _64 = '64px'
export const _80 = '80px'
export const _96 = '96px'
export const _112 = '112px'
export const _128 = '128px'
export const _144 = '144px'
export const _160 = '160px'
export const _192 = '192px'
export const _208 = '208px'
export const _224 = '224px'
export const _240 = '240px'
export const _256 = '256px'
export const _288 = '288px'
export const _320 = '320px'
export const _384 = '384px'
